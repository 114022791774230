import Bugsnag from '@bugsnag/js'

Bugsnag.start({
  apiKey: 'd8bc56bb9ea160ab167e05065fa501f6',
  appType: 'js',
  
  enabledReleaseStages: ['production'],
  user: {
    name: window.username,
  },
})
